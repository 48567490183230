<template>
  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <b-row class="match-height">
      <div class="col-12">
        <div class="card">
          <div class="card-header border-bottom">
            <h4 class="card-title">
              Transactions
            </h4>
          </div>
          <div class="card-datatable">
            <table class="datatables-ajax table">
              <thead>
                <tr>
                  <th>SN</th>
                  <th>Name</th>
                  <th>Reference</th>
                  <th>Amount</th>
                  <th>Type</th>
                  <th>Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tfoot>
                <tr>
                  <th>SN</th>
                  <th>Name</th>
                  <th>Reference</th>
                  <th>Amount</th>
                  <th>Type</th>
                  <th>Date</th>
                  <th>Status</th>
                </tr>
              </tfoot>
              <tbody>
                <tr
                  v-for="(transaction, index) in transactions"
                  :key="index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ transaction.beneficiary }}</td>
                  <td>{{ transaction.reference }}</td>
                  <td>₦{{ transaction.amount_formatted }}</td>
                  <td :class="transaction.type === 'debit' ? 'text-danger' : 'text-success'">
                    {{ transaction.type }}
                  </td>
                  <td>{{ transaction.created_at_formatted }}</td>
                  <td :class="transaction.status === 'failed' ? 'text-danger' : transaction.status === 'pending' ? 'text-warning' : 'text-success'">
                    {{ transaction.status }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>           
      </div>
    </b-row>
    <div class="mx-2 mb-2">
      <nav aria-label="Page navigation">
        <ul class="pagination">
          <li :class="link.active ? 'page-item active' : 'page-item'" v-for="link in meta.links" :key="link.id">
            <a class="page-link" href="javascript:;" @click="paginate(link.url)" v-html="link.label"></a>
          </li>
        </ul>
      </nav>        
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BLink,
  BBadge, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BLink,
    BBadge,
    BPagination,
    vSelect,
  },
  data() {
    return {
      transactions: [],
      searchQuery: {
        text: '',
        status: '',
      },
      meta: {},
      links: {},
      url: '',
      statusOptions: [
        'success',
        'pending',
        'failed',
      ],
    }
  },
  computed: {
    transactionFilter() {
      return this.transactions.filter(trans => trans.type === this.searchQuery.text || trans.beneficiary.toLowerCase().includes(this.searchQuery.text) || trans.beneficiary.includes(this.searchQuery.text) || trans.status === this.searchQuery.status)
    },
  },
  created() {
    this.url = this.$url+'/transactions'
    this.getTransactions()
  },
  methods: {
    paginate(url) {
      this.url = url
      this.getTransactions()
    },     
    getTransactions() {
      this.$http.get(this.url)
        .then(response => {
          console.log('===>', response.data.data)
          if (response.data.status) {
            this.transactions = response.data.data.data
            this.links = response.data.data.links
            this.meta = response.data.data.meta
            // this.transactions = response.data.data.data.map(trans => ({
            //   agent_id: trans.agent_id,
            //   beneficiary: trans.beneficiary,
            //   amount: trans.amount_formatted,
            //   type: trans.type,
            //   sub_type: trans.sub_type,
            //   description: trans.description,
            //   date: trans.created_at_formatted,
            // }))
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
